export class MensagemOrcamentoModel {
  constructor({
    id,
    sequencia,
    titulo,
    classificacao,
    descricao,
    flagObrigatorio = false,
  }) {
    this.id = id;
    this.titulo = titulo;
    this.sequencia = sequencia;
    this.classificacao = classificacao;
    this.descricao = descricao;
    this.flagObrigatorio = flagObrigatorio;
  }
}

<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form
      ref="form"
      class="row"
    >
      <input-text
        :value="form.sequencia"
        disabled
        type="number"
        class="col-12 col-md-2"
        :label="$t('modulos.mensagem_orcamento.formulario.sequencia')"
        :placeholder="$t('modulos.mensagem_orcamento.formulario.sequencia')"
      />
      <input-text
        v-model="form.titulo"
        class="col-12 col-md-4"
        :label="$t('modulos.mensagem_orcamento.formulario.titulo')"
        :placeholder="$t('modulos.mensagem_orcamento.formulario.titulo_placeholder')"
        :max="200"
        obrigatorio
        trim
      />
      <input-select
        v-model="form.classificacao"
        :options="opcoes.classificacao"
        class="col-12 col-md-4"
        :label="$t('modulos.mensagem_orcamento.formulario.classificacao')"
        obrigatorio
      />
      <input-select
        v-model="form.flagObrigatorio"
        :options="opcoes.boleano"
        class="col-12 col-md-2"
        :label="$t('modulos.mensagem_orcamento.formulario.obrigatorio')"
      />
      <input-textarea
        v-model="form.descricao"
        class="col-12"
        :label="$t('modulos.mensagem_orcamento.formulario.descricao')"
        :placeholder="$t('modulos.mensagem_orcamento.formulario.descricao_placeholder')"
        :max="5000"
        obrigatorio
        trim
      />
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import MensagemOrcamentoService from '@common/services/cadastros/MensagemOrcamentoService';
import EnumeradorService from "@common/services/cadastros/EnumeradorService";
import { MensagemOrcamentoModel } from '@common/models/cadastros/MensagemOrcamentoModel';
import helpers from '@/common/utils/helpers';
export default {
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new MensagemOrcamentoModel({}),
      opcoes: {
        classificacao: [],
        boleano: helpers.BoleanoEnum
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.mensagem_orcamento.titulos.editar');
      return this.$t('modulos.mensagem_orcamento.titulos.novo');
    },
  },
  mounted() {
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'Mensagem', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'Mensagem', 'Inserir');


    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
    if (!this.id) this.buscarUltimaSequencia();
    this.buscarClassificacao();
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      MensagemOrcamentoService.buscar(this.id)
        .then((res) => {
          this.form = new MensagemOrcamentoModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.mensagem_orcamento.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.valido = this.$refs.form.validate()
        });
    },
    confirmacaoSalvar: function () {
        if(!this.id){
          this.salvar();
          return
        }
        this.confirmarSalvar().then(() => {
            this.salvar();
          });
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if(!this.valido) return
      this.$store.dispatch('Layout/iniciarCarregamento');
      MensagemOrcamentoService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'mensagem-orcamento' });
          this.toastSucesso(this.$t(`modulos.mensagem_orcamento.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'mensagem-orcamento' });
      });
    },
    buscarClassificacao: function () {
      EnumeradorService.buscar('EnumMensagemClassificacao').then((res) => {
        this.opcoes.classificacao = res
      });
    },
    buscarUltimaSequencia() {
      if (this.id) return;
      MensagemOrcamentoService.listar({ amountPerPage: 20000 }).then((res) => {
        if (res.data.items.length === 0) {
          this.form.sequencia = 1;
          return;
        }
        const objetoComMaiorSequencia = res.data.items.reduce((objetoA, objetoB) => {
          if (objetoA.sequencia > objetoB.sequencia) {
            return objetoA;
          } else {
            return objetoB;
          }
        });
        this.form.sequencia = ++objetoComMaiorSequencia.sequencia;
      })

    },
  },
};
</script>
